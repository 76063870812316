@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&family=Montserrat:wght@400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');

/* Verhindere Scrollen */
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Verhindert das Scrollen */
  height: 100%;
  width: 100%;
}

/* Authentifizierungs-Container */
.auth {
  background-image: url('images/background.svg');
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100vh; /* volle Höhe des Viewports */
  width: 100vw;  /* volle Breite des Viewports */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 0.5fr;
  justify-items: center;
  align-items: center;
}

/* Verhindere das Scrollen im gesamten HTML */
html, body {
  height: 100%;
  width: 100%;
  overflow: hidden; /* Kein Scrollen */
}





.auth-headline{
  left: 1vw;
  height: 8vh;
  position: relative;
  top: 16vh;
  grid-row: 1/2;
  grid-column: 2/3;
}


.auth-avatar-div {
  position: relative;
  top: 3vh;
  left: 1vw;
  grid-row: 2/3;
  grid-column: 2/3;
  position: relative; /* Damit die Kinder relativ dazu positioniert werden können */
  width: 12vh; /* Breite des Containers anpassen */
  height: 12vh; /* Höhe des Containers anpassen */
  display: flex; /* Flexbox für die Zentrierung */
  justify-content: center; /* Horizontale Zentrierung */
  align-items: center; /* Vertikale Zentrierung */
}

.auth-avatar-background {
  position: absolute; /* Hintergrundbild über den Avatar legen */
  width: 100%; /* Breite anpassen */
  height: 100%; /* Höhe anpassen */
}

.auth-avatar {
  position: relative; /* Avatar relativ zum Container */
  z-index: 2; /* Avatar über dem Hintergrundbild */
  width: 10vh; /* Breite des Containers anpassen */
  height: 10vh; /* Höhe des Containers anpassen */
}



.inspiration-note{
  left: 1vw;
  position: relative;
  top: 21.5vh;
  grid-row: 1/2;
  grid-column: 2/3;
  font-family: 'Passion One';
  font-size: 2vh;
  line-height: 29.26px;
  text-align: center;
  color: #FFFFFF

}

.inspiration-note .youtuber-link {
  color: #9375ff; /* Wähle deine Farbe */
  text-decoration: none;
}

.inspiration-note .youtuber-link:hover {
  text-decoration: underline;
}





.create-room-button {
  position: relative;
  bottom: 11.5vh;
  left: 1vw;
  grid-row: 3/4;
  grid-column: 2/3;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  width: 9.5vw;
  height: 3vw;
  color: #fff;
  font-size: 2.2vh;
  font-family: 'Passion One';
  font-weight: 700;

  background: #AD09BE;
  &.create-room-button {
    text-transform: uppercase;
    border-radius: 0.4em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {

      position: absolute;
      content: '';
      padding:0px;

      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #8A0997;
      border-radius: inherit;
      transform: translate3d(0, 0.5em, -1em); /* schatten vor hover */
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      transform: translate(0, 0.15em); /* das muss die differenz sein sonst gibt es buggs */
      &::before {
        transform: translate3d(0, 0.35em, -1em); /* schatten während hover */
      }
    }
    &:active {

      transform: translate(0em, 0.5em);
      &::before {
        transform: translate3d(0, 0, -1em);
      }
    }
      /* Deaktivierter Zustand */
  &:disabled {
    background: hsl(294, 50%, 39%);

  }

  &:disabled::before {

    background: hsl(295, 50%, 31%); /* Grauer Hintergrund */
  }
  &:hover {
    transform: translate(0, 0.15em);
    &::before {
      transform: translate3d(0, 0.35em, -1em); /* Schatten während Hover */
    }
  }
}
}


.auth{
  background-image: url('images/background.svg');
  background-position: center;
  background-size: cover; /* oder contain */
  position: relative;
  height: 100vh; /* volle Höhe des Viewports */
  width: 100vw; /* volle Breite des Viewports */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-template-rows: 1fr 1fr 1fr 0.5fr;
  justify-items: center;
  align-items: center;
 
}












.auth-error {
  position: relative;
  bottom: 11.5vh;
  left: 1vw;
  grid-row: 3/4;
  grid-column: 2/3;
  font-family: 'Passion One';
  height: 0.5vh;
  font-size: 1.8vh;
  text-align: center;
  color: #e32636
}







.info-links {
  position: relative;
  top: 2.25vw;
  left: 0vw;
  grid-row: 4/5;
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  gap: 15vw; /* Abstand zwischen den Links */
}

.info-link {
  white-space: nowrap; /* Verhindert Zeilenumbrüche */

  font-family: 'Passion One';
  font-size: 2.3vh;
  text-align: center;
  text-decoration: none;
  color: white

}

.discord-link{
  position: absolute;
  top: -1.4vh;
  width: 2.2vw;
}


.discord-server-link{
  position: absolute;
  bottom: 6.5vw;;
  left: 49.5vw;
}
  

.auth-form {
  grid-row: 3/4;
  grid-column: 2/3;
  display: flex;                /* Setzt das Flexbox-Modell ein */
  align-items: center;          /* Zentriert den Inhalt vertikal */
  flex-direction: column;       /* Ordnet die Kinder vertikal an */
}
.auth-input{
  position: relative;
  bottom: 10vh;
  left: 1vw;


  font-family: 'Passion One';
  /*width: 300px; */
  width: 28vh;

  height: 5vh;
  border-radius: 0.8vh;
  font-size: 3vh; /* Beispiel für größere Schriftgröße */
  text-align: left; /* Text linksbündig ausrichten */
  padding-left: 1vh; 
  padding-right: 1vh; 

  padding-top: 0.1vh; 
  padding-bottom: 0.1vh; 

  border: 0.5vh solid #9375FF;
  background: #FFFFFF;
  color: #808080; /* Beispiel für eine hellblaue Schriftfarbe */
  caret-color: #808080;
}

input:focus {
  outline: none; /* Entfernt die Standard-Umrandung */
}

.info-link:hover {
  text-decoration: underline; /* Entfernt die Unterstreichung beim Hover-Effekt */
}





.Auth-SoundToggle{
  position: absolute;
  left: 8vw;
  bottom: 84vh;
}

