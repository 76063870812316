.WinnerScreen-container {
  background-image: url('images/background.svg');
  background-position: center;
  background-size: cover; /* oder contain */
  height: 100vh; /* volle Höhe des Viewports */
  width: 100vw; /* volle Breite des Viewports */

  }

  .WinnerScreen-confetti-layer{
    /*background-image: url('images/konfettiLayer.svg');*/

    background-position: center;
    background-size: cover; /* oder contain */
    height: 100vh; /* volle Höhe des Viewports */
    width: 100vw; /* volle Breite des Viewports */

    display: flex;
    justify-content: center; /* Horizontal zentrieren */
    align-items: center; /* Vertikal zentrieren */
  }
  
.WinnerScreen-trophy{
  width: 17.5vw;
  position: absolute;
}
  
.WinnerScreen-nerd-face{
  width: 10vw;
  position: absolute;
  bottom: 44vh;
}

.WinnerScreen-Player {
  bottom: 22vh;
  position: absolute;




  background-color: white;
  border-radius: 3vw;
  display: flex;
  width: 12.25vw;
  height: 3.5vw;
  border: None;  
}

.WinnerScreen-player-avatar-container{

  top: 0.25vw;
  position: relative;
  left: 0.3vw;
  position: relative; /* Damit die Kinder relativ dazu positioniert werden können */
  width: 3vw; /* Breite des Containers anpassen */
  height: 3vw; /* Höhe des Containers anpassen */
  display: flex; /* Flexbox für die Zentrierung */
  justify-content: center; 
  align-items: center; 
}

.WinnerScreen-button-div{
  position: absolute;
  right: 7vw;
  bottom: 7vh;

}

.WinnerScreen-button{
  position: relative;


  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  width: 11vw;
  height: 4vw;
  color: #fff;
  font-size: 1.2vw;
  font-family: 'Passion One';
  font-weight: 700;

  background: #AD09BE;
  &.WinnerScreen-button {
    text-transform: uppercase;
    border-radius: 0.4em;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
    &::before {
      position: absolute;
      content: '';
      padding:0px;

      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #8A0997;
      border-radius: inherit;
      transform: translate3d(0, 0.5em, -1em); /* schatten vor hover */
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      transform: translate(0, 0.15em); /* das muss die differenz sein sonst gibt es buggs */
      &::before {
        transform: translate3d(0, 0.35em, -1em); /* schatten während hover */
      }
    }
    &:active {

      transform: translate(0em, 0.5em);
      &::before {
        transform: translate3d(0, 0, -1em);
      }
    }
      /* Deaktivierter Zustand */
  &:disabled {
    background: hsl(294, 50%, 39%);

  }

  &:disabled::before {

    background: hsl(295, 50%, 31%); /* Grauer Hintergrund */
  }
  &:hover {
    transform: translate(0, 0.15em);
    &::before {
      transform: translate3d(0, 0.35em, -1em); /* Schatten während Hover */
    }
  }
}
}
.message-notice {
  position: absolute;
  bottom: 7.75vw;
  color: rgb(207, 26, 26);
  font-size: 0.95vw;
  right: 7.2vw;
  font-family: 'Passion One';

}
